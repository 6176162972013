import React, { useContext, useState } from "react"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { useLocalStorage } from "react-use"
import { FaFileImage } from "react-icons/fa"
import SubmissionDialog from "./submission-dialog"
import { FirebaseContext } from "../../services"
import { wePhealtThatRef } from "../../state/FirebaseProvider"
import { motion } from "framer-motion"

export default function CreateStory() {
    //Context
    const { firebase } = useContext(FirebaseContext)

    //State
    const [storyId, setStoryId] = useState(wePhealtThatRef.doc().id)
    const [storyPhotos, setStoryPhotos] = useState(["", ""])
    const [storyPhotoUrls, setStoryPhotoUrls] = useState(["", ""])
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showSaveMessage, setShowSaveMessage] = useState(false)

    //Custom Hooks
    const [wptStory, setStory] = useLocalStorage("wptStory")

    const createFlyer = (e, index) => {
        e.preventDefault()
        if (e.target.files[0]) {
            //Add the file to state
            const currentPhotos = [...storyPhotos]
            const currentUrls = [...storyPhotoUrls]
            currentPhotos[index] = e.target.files[0]
            currentUrls[index] = URL.createObjectURL(e.target.files[0])
            setStoryPhotos(currentPhotos)
            setStoryPhotoUrls(currentUrls)
        } else {
            alert("Nothing selected!")
        }
    }

    const resetState = () => {
        setStoryId(wePhealtThatRef.doc().id)
        setStoryPhotos(["", ""])
        setStoryPhotoUrls(["", ""])
        setStory("")
    }

    const handleUpload = async () => {
        //Open the modal and start loading
        setLoading(true)
        setModalOpen(true)

        try {
            const uploadTasks = storyPhotos.map(file => uploadAndGetUrl(file))
            const downloadUrls = await Promise.all(uploadTasks)
            const story = {
                text: wptStory,
                photos: downloadUrls,
            }

            await wePhealtThatRef.doc(storyId).set(story)
            setLoading(false)
            resetState()
        } catch (err) {
            console.log(err.message)
        }
    }

    const uploadAndGetUrl = file => {
        let imageRef = firebase
            .storage()
            .ref()
            .child(`wptStories/${storyId}/${file.name}`)

        return new Promise(function (resolve, reject) {
            const uploadTask = imageRef.put(file)
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                    var progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                },
                function error(err) {
                    console.log("error", err)
                    reject()
                },
                function complete() {
                    uploadTask.snapshot.ref
                        .getDownloadURL()
                        .then(function (downloadURL) {
                            resolve(downloadURL)
                        })
                }
            )
        })
    }

    return (
        <Container id="createStory">
            <TextWrapper>
                <AutoSaveNote
                    initial={{ opacity: 0 }}
                    animate={{ opacity: showSaveMessage ? 1 : 0 }}
                >
                    You can take your time. We’ll auto save your story for you
                    so you can always come back to it later until you’re
                    finished.
                </AutoSaveNote>
            </TextWrapper>
            <div>
                <TextBox
                    placeholder="Start your story here..."
                    rows={20}
                    onChange={e => setStory(e.target.value)}
                    defaultValue={wptStory}
                    onInput={() => !showSaveMessage && setShowSaveMessage(true)}
                />
            </div>
            <MediaUpload>
                <TextWrapper>
                    <Note>
                        Please include <u>TWO</u> photos that give some context and meaning to
                        your story. They don’t need to be directly tied to your
                        story.
                    </Note>
                </TextWrapper>
                <Grid>
                    <FieldFile
                        type="file"
                        name="logo"
                        id="logo"
                        onChange={e => createFlyer(e, 0)}
                    />
                    <FileLabel selected={storyPhotoUrls[0]} for="logo">
                        <FaFileImage style={{ marginBottom: 20 }} size={45} />{" "}
                        {storyPhotoUrls[0] ? "Uploaded" : "Click to upload"}
                    </FileLabel>
                    <FieldFile
                        type="file"
                        name="logo2"
                        id="logo2"
                        onChange={e => createFlyer(e, 1)}
                    />
                    <FileLabel selected={storyPhotoUrls[1]} for="logo2">
                        <FaFileImage style={{ marginBottom: 20 }} size={45} />{" "}
                        {storyPhotoUrls[1] ? "Uploaded" : "Click to upload"}
                    </FileLabel>
                </Grid>
            </MediaUpload>
            <Flex align="center" justify="center">
                <MainButton
                    dark
                    onClick={() => handleUpload()}
                    disabled={!storyPhotoUrls[0] || !storyPhotoUrls[1]}
                >
                    Submit Anonymously
                </MainButton>
            </Flex>
            <SubmissionDialog
                open={modalOpen}
                setOpen={setModalOpen}
                loading={loading}
            />
        </Container>
    )
}

const Container = styled.div`
    /* padding: 5vh 20px; */
    width: 100%;
`
const AutoSaveNote = styled(motion.p)`
    margin-bottom: 30px;
    color: #3c3c3c;
    line-height: 1.4;
    font-size: 16px;
`
const TextBox = styled.textarea`
    border: 1px solid #b2b2b2;
    font-family: "Haas";
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 30px;
    padding: 15px 0 15px 10px;
    margin-left: -5px;
    width: 100%;

    ::placeholder {
        color: #a7a7a7;
    }
`
const MediaUpload = styled.div``
const Note = styled.p`
    line-height: 1.4;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
`
const FieldFile = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`

const FileLabel = styled.label`
    margin: 20px 0;
    font-size: 1em;
    font-weight: 500;
    color: #3c3c3c;
    font-family: "Haas", sans-serif;
    border: 1px solid #b2b2b2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 20px;
    transition: 0.3s ease;
    cursor: pointer;
    :hover {
        background-color: #ddd;
    }
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`
