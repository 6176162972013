import { Dialog } from "@material-ui/core"
import React from "react"
import Loader from "react-loader-spinner"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"

const SubmissionDialog = ({ open, setOpen, loading }) => {
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            keepMounted
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
        >
            <Container>
                <TextWrapper>
                    <Flex direction="column" align="center">
                        <Logo />
                        {loading && (
                            <Loader
                                type="Oval"
                                height={55}
                                width={55}
                                color={colors.pink}
                                style={{ marginBottom: 30 }}
                            />
                        )}
                        {loading ? (
                            <LoadingText>
                                Working on uploading your story…
                            </LoadingText>
                        ) : (
                            <LoadingText>
                                Upload Successfully!
                                <br />
                                Once approved, your story will appear on our
                                WePHEALThat page
                            </LoadingText>
                        )}
                        <TextWrapper>
                            <Note>
                                Thank you for choosing to evolve with us.
                                <br />
                                <br />
                                Being a part of PHEAL means becoming part of a
                                new movement of people looking to shape our
                                world for the better. We’re working to create a
                                community that genuinely teaches, supports and
                                learns from each other so that together, we can
                                all grow and evolve.
                            </Note>
                        </TextWrapper>
                        {!loading && (
                            <MainButton onClick={handleClose} dark>
                                Finish
                            </MainButton>
                        )}
                    </Flex>
                </TextWrapper>
            </Container>
        </Dialog>
    )
}
export default SubmissionDialog

const Container = styled.div`
    padding: 40px 25px;
`
const Logo = styled.img``
const LoadingText = styled.p`
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
`
const Note = styled.p`
    text-align: center;
    color: #464646;
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 30px;
`
